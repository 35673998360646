
<template>
    <div class="blacklistmain">
        <el-dialog :title="addandedit" :visible.sync="dialogVisible" width="30%">
            <el-form label-width="80px" v-loading="loading">
                <el-form-item label="访客编号：">
                    <el-input v-model="usernumber" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="访客IP：">
                    <el-input type="text" v-model="userip" maxlength="15" placeholder="请输入IP地址" ref="inputip"
                        :disabled="inipstatus"></el-input>
                    <!-- <span>-</span><el-input type="text" v-model="userport" maxlength="3"></el-input><span> 例：192.168.1.100 - 255</span> -->
                </el-form-item>
                <el-form-item label="失效时间：">
                    <el-date-picker placeholder="为空则不失效" v-model="invalidtimes" type="datetime" format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm" :picker-options="pickerOption"
                        @change="beginDateChange"></el-date-picker>
                </el-form-item>
                <el-form-item label="阻止原因：">
                    <el-input placeholder="备注" v-model="hindercause" type="textarea"
                        :autosize="{ minRows: 5, maxRows: 520 }"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tobaklist">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <div class="blacklistbox">
            <el-button type="primary" class="addblacklist" @click="openaddbaklst">添加黑名单</el-button>
            <span>访客IP：</span>
            <el-input placeholder="输入访客IP搜索" class="inputip" v-model="searchip" size="mini" clearable></el-input>
            <el-button @click="searchbaklsts">搜索</el-button>
            <!-- <el-button>下载</el-button> -->
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button :disabled="edit" @click="openeditbaklst">编辑</el-button>
            <el-button :disabled="del" @click="delbaklst()">删除</el-button>
        </div>
        <el-table ref="multipleTable" :data="blacklists" tooltip-effect="dark" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="访客编号" width="100">
            </el-table-column>
            <el-table-column prop="ip" label="访客ip" width="150">
            </el-table-column>
            <el-table-column prop="region" label="地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="endtime" label="失效时间" width="150"> </el-table-column>
            <el-table-column prop="admin_id" label="添加客服" width="150"> </el-table-column>
            <el-table-column prop="createtime" label="添加时间" width="200" sortable>
            </el-table-column>
            <el-table-column prop="remark" label="阻止原因"> </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next, sizes" :total="totpages" :page-sizes="[10, 20, 50, 100]"
            :page-size="10" @current-change="baktopage" @size-change="bakeditpages">
        </el-pagination>
    </div>
</template>
<script>

import { getbaklst,addbaklst,editbaklst,delbaklst } from "../../api/jiekou"

export default {
    name: "XianmuHmd",
    created() {

    },
    mounted() {
        this.getbaklsts()
    },
    methods: {
         // 重置按钮
         reset(){
            this.userip=""
            this.getbaklsts()
            this.$message({
          message: '重置成功',
          type: 'success'
        });
        },
        async addbaklsts(val) {
            let addblt = await addbaklst(val)
            return addblt
        },
        async getbaklsts() {
            let baklst = await getbaklst()
            if (baklst.data.code==-1) {
                alert("你的用户名失效，"+baklst.data.msg)
                this.$router.replace("/login");
            } else{
                baklst.data.data.data.forEach(val => {
                    val.createtime = this.timestampToTime(val.createtime)
                    val.endtime = this.timestampToTime(val.endtime)
                    if (val.endtime == "1970-01-01 8:0:0") {
                        val.endtime = "不失效"
                    }
                })
                this.blacklists = baklst.data.data.data
                this.totpages = baklst.data.data.count
            }
        },
        async searchbaklsts() {
            let searchbaklst = await getbaklst({ ip: this.searchip })
            searchbaklst.data.data.data.forEach(val => {
                val.createtime = this.timestampToTime(val.createtime)
                val.endtime = this.timestampToTime(val.endtime)
                if (val.endtime == "1970-01-01 8:0:0") {
                    val.endtime = "不失效"
                }
            })
            this.blacklists = searchbaklst.data.data.data
            this.totpages = searchbaklst.data.data.count
        },
        async baktopage(val) {
            let topage = await getbaklst({ page: val })
            topage.data.data.data.forEach(val => {
                val.createtime = this.timestampToTime(val.createtime)
                val.endtime = this.timestampToTime(val.endtime)
                if (val.endtime == "1970-01-01 8:0:0") {
                    val.endtime = "不失效"
                }
            })
            this.blacklists = topage.data.data.data
        },
        async bakeditpages(val) {
            let topage = await getbaklst({ limit: val })
            topage.data.data.data.forEach(val => {
                val.createtime = this.timestampToTime(val.createtime)
                val.endtime = this.timestampToTime(val.endtime)
                if (val.endtime == "1970-01-01 8:0:0") {
                    val.endtime = "不失效"
                }
            })
            this.blacklists = topage.data.data.data
        },
        async delbaklst() {
            let _this = this
            this.multipleSelection.forEach(function (val) {
                _this.delbaklsts.push(val.id)
            })
            await delbaklst({ id: this.delbaklsts.toString() }).then(res => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.getbaklsts()
                } else {
                    this.meswarning("删除失败，请稍后再试。")
                }
            }).catch(() => {
                this.meswarning("删除失败，请稍后再试。")
            })
        },
        openaddbaklst() {
            this.addandedit = "添加黑名单"
            this.userip = ""
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs.inputip.focus()
            })
        },
        openeditbaklst() {
            this.addandedit = "编辑黑名单"
            this.userip = this.multipleSelection[0].ip
            this.multipleSelection[0].endtime != "不失效" ? this.invalidtimes = this.multipleSelection[0].endtime : this.invalidtimes = ""
            this.multipleSelection[0].remark ? this.hindercause = this.multipleSelection[0].remark : this.hindercause = "";
            this.inipstatus = true
            this.dialogVisible = true
            this.editbaklsts = {
                id: this.multipleSelection[0].id,
                ip: this.multipleSelection[0].ip,
            }
        },
        async tobaklist() {
            if (this.inipstatus) {
                this.editbaklsts.endtime = this.invalidtimes
                this.editbaklsts.remark = this.hindercause
                if (this.editbaklsts.endtime == "") Reflect.deleteProperty(this.editbaklsts, 'endtime')
                if (this.editbaklsts.remark == "") Reflect.deleteProperty(this.editbaklsts, 'remark')
                // console.log(this.editbaklsts)
                await editbaklst(this.editbaklsts).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '编辑成功！',
                            type: 'success'
                        });
                        this.dialogVisible = false
                        this.getbaklsts()
                    } else {
                        this.meswarning("编辑失败，请稍后再试。")
                    }
                }).catch(() => {
                    this.meswarning("编辑失败，请稍后再试。")
                })
            } else {
                if (this.userip == "") {
                    this.meswarning("请输入IP地址")
                    return false;
                } else if (!this.ippost(this.userip)) {
                    this.meswarning("请输入正确的IP地址")
                    return false;
                }
                if (this.userport != "") {
                    this.userport = parseInt(this.userport);
                    if (!this.ippost(this.userport)) {
                        this.meswarning("范围为0~255")
                        return false;
                    }
                }
                let addpar = {
                    admin_id: 10,
                    ip: this.userip
                }
                this.hindercause ? addpar.remark = this.hindercause : Reflect.deleteProperty(addpar, 'remark')
                this.invalidtimes ? addpar.endtime = this.invalidtimes : Reflect.deleteProperty(addpar, 'endtime')
                this.loading = true
                this.addbaklsts(addpar).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '添加成功！',
                            type: 'success'
                        });
                        this.loading = false
                        this.dialogVisible = false
                        this.getbaklsts()
                    } else {
                        this.loading = false
                        this.meswarning("添加失败，请稍后再试。")
                    }
                }).catch(() => {
                    this.loading = false
                    this.meswarning("添加失败，请稍后再试。")
                })
            }
        },
        timestampToTime(timestamp) {
            // 时间戳为10位需*1000，时间戳为13位不需乘1000
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + "-";
            var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
            var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
        },
        beginDateChange(date) {
            if (!date) return;
            var startAt = new Date(date).getTime();
            if (startAt < Date.now()) {
                this.invalidtimes = this.formatDate(new Date(), "yyyy-MM-dd HH:mm");
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            val.length ? (this.del = false) : (this.del = true);
            val.length == 1 ? (this.edit = false) : (this.edit = true);
        },
        meswarning(val) {
            this.$message({
                showClose: true,
                message: val,
                type: 'error'
            });
        },
        ippost(val) {
            if (typeof val == "number") {
                return /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})$/.test(val)
            } else {
                return /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/.test(val)
            }
        },
        formatDate(objDate, fmt) {
            var o = {
                "M+": objDate.getMonth() + 1, //月份
                "d+": objDate.getDate(), //日
                "h+": objDate.getHours() % 12 == 0 ? 12 : objDate.getHours() % 12, //小时
                "H+": objDate.getHours(), //小时
                "m+": objDate.getMinutes(), //分
                "s+": objDate.getSeconds(), //秒
                "q+": Math.floor((objDate.getMonth() + 3) / 3), //季度
                "S": objDate.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(RegExp.$1, (objDate.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        }
    },
    data() {
        return {
            addandedit: "",
            delbaklsts: [],
            editbaklsts: "",
            inipstatus: false,
            searchip: "",
            usernumber: "",
            userip: "",
            userport: "",
            invalidtimes: "",
            hindercause: "",
            dialogVisible: false,
            loading: false,
            pickerOption: {
                disabledDate: (time) => {
                    return Date.now() - 3600 * 1000 * 24 > time.getTime();
                    // return time.getTime() < Date.now() - 8.64e7;  //如果没有后面的-8.64e7就是不可以选择今天的 
                },
            },
            totpages: 0,
            blacklists: [],
            del: true,
            edit: true,
        };
    },

};
</script>
<style scoped>
.blacklistmain {
    padding: 0 15px;
    height: calc(100vh - 70px);
}

.addblacklist {
    width: 85px;
}

.blacklistbox>* {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 12px;
    border-radius: 2px;
    font-size: 12px;
}

.inputip {
    width: 135px;
    height: 28px;
    padding: 0;
    padding-right: 3px;
    margin-right: 12px;
    box-sizing: initial;
}

.inputip ::v-deep .el-input__inner {
    height: 28px;
    padding: 0 10px;
    border-radius: 2px;
}

.blacklistbox {
    display: flex;
    align-content: center;
    margin: 10px 0;
}

.blacklistbox span {
    line-height: 28px;
    padding-right: 0;
}

.blacklistmain ::v-deep .el-table__header-wrapper {
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #d8dfe6;
    background-color: #f4f7fb;
}

.blacklistmain ::v-deep .el-table .el-table__header-wrapper th {
    padding: 0;
    font-size: 12px;
    background-color: #f4f7fb;
}

.blacklistmain ::v-deep .el-table .el-table__header-wrapper .el-checkbox {
    margin-left: 3px;
}

.blacklistmain ::v-deep .el-table .el-table__body-wrapper td {
    height: 36px;
    padding: 0;
}

.blacklistmain ::v-deep .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    padding-bottom: 30px;
    margin-top: 18px;
}

.blacklistmain ::v-deep .el-pagination__sizes {
    margin-right: 0;
}

.blacklistmain ::v-deep .el-pagination__sizes .el-input {
    margin-right: 0;
}

.blacklistmain ::v-deep .el-pager li,
.blacklistmain ::v-deep .el-pagination button {
    border: 1px solid #ebebeb;
    background-color: #fff;
    font-weight: initial;
    margin: 0 3px;
}

.blacklistmain ::v-deep .el-dialog__header {
    padding: 0;
    color: #28334b;
    background-color: #f7f8fa;
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #e3e7ed;
    border-left: 0;
    border-right: 0;
}

.blacklistmain ::v-deep .el-dialog__header .el-dialog__title {
    font-size: 14px;
    line-height: 36px;
    padding-left: 10px;
    color: #303133;
}

.blacklistmain ::v-deep .el-dialog__headerbtn {
    top: 10px;
    right: 10px;
}

.blacklistmain ::v-deep .el-dialog {
    width: 420px !important;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer {
    padding-bottom: 16px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer button {
    width: 52px;
    height: 28px;
    padding: 0;
    border-radius: 2px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer button:hover {
    color: #606266;
    background: #f3f3f3;
    border: 1px solid #dcdfe6;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer .el-button--primary {
    background: #ff5b4d;
    border-color: #ff5b4d;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer .el-button--primary:hover {
    color: #fff;
    background: #e74f41;
    border-color: #e74f41;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__footer button span {
    font-size: 14px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body {
    padding: 18px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body>div {
    display: flex;
    align-items: center;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form label {
    font-weight: initial;
    font-size: 12px;
    padding-right: 0;
    width: 60px !important;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form .el-form-item {
    margin-bottom: 12px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form .el-form-item:first-child {
    display: none;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form .el-form-item:nth-child(4) textarea {
    border-radius: 2px;
    font-size: 12px;
    padding: 2px 8px;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form .el-form-item:nth-child(3) .el-input__prefix {
    display: none;
}

.blacklistmain ::v-deep .el-dialog .el-dialog__body .el-form .el-form-item__content {
    margin-left: 60px !important;
}

.blacklistmain ::v-deep .el-dialog input {
    height: 28px;
    border-radius: 2px;
    padding: 0 8px;
}

.blacklistmain ::v-deep .el-dialog__body .el-input {
    width: 173px;
    font-size: 12px;
}

/* .blacklistmain ::v-deep .el-dialog__body .el-form-item:nth-child(2) .el-input:nth-of-type(1) {
  width: 110px;
} */

.blacklistmain ::v-deep .el-dialog__body .el-input:nth-of-type(2) {
    width: 45px;
}

.blacklistmain ::v-deep .el-dialog__body .el-form-item:nth-child(2) span {
    font-size: 12px;
}

.blacklistmain ::v-deep .el-dialog__body .el-form-item:nth-child(2) span:nth-of-type(1) {
    margin: 0 7px;
}

.blacklistmain ::v-deep .el-dialog__body span:last-child {
    color: #95a9bb;
}
</style>